import request from '../utils/request'

/**
 * 获取balance
 */
export const getBalance = () => {
    return request({
        url: '/save/open/getBalance',
        method: 'get'
    })
}

/**
 * 充值
 */
export const charge = (params: any) => {
    return request({
        url: '/save/open/charge',
        method: 'post',
        data: params
    })
}
