<template>
  <ion-page>
    <ion-content class="page" :fullscreen="true">
      <div v-if="!isLogin">
        <div class="login-title">
          您还未登录，登录后才可以查看
        </div>
        <div>
          <a class="login-button" href="/login">登录</a>
        </div>
      </div>
      <div v-if="isLogin">
        <div class="form-row">
          <label class="label">是否开始是否：</label>
          <div class="form-value">
            <span class="balance cash-balance">{{ startCharge ? "是" : "否" }}</span>
          </div>
        </div>
        <div class="form-row">
          <label class="label">可用余额：</label>
          <div class="form-value">
            <span class="balance cash-balance">￥{{ balance }}</span>
          </div>
        </div>
        <div class="form-row">
          <label class="label">每次调用价格：</label>
          <div class="form-value">
            <span class="balance cash-balance">￥{{ price }}</span>
          </div>
        </div>
        <div class="form-row">
          <label class="label">预估剩余次数：</label>
          <div class="form-value">
            <span class="balance cash-balance">{{ restAmount }}次</span>
          </div>
        </div>
        <div class="form-row">
          <label>充值金额：</label>
          <div class="form-value">
            <div class="other-amount">
              <input oninput="value=value.replace(/[^\?\d.]/g,'')" v-model="chargeAmount" class="pay-textbox"
                     id="ctrl-i-otherAmount-input"
                     placeholder="输入金额" style="width: 80px; height: 36px;">
              <span class="amount-tip">元</span>
              <span data-ui-type="Validity" data-ui-id="amountValidity" id="ctrl-i-amountValidity"
                    data-ctrl-id="amountValidity" data-ctrl-view-context="i"
                    class="ui-ctrl ui-validity ui-validity-hidden state-hidden"></span>
            </div>
          </div>
        </div>
        <div class="form-row form-submit-row">
          <button class="recharge-button confirm-button ui-ctrl ui-button" id="ctrl-i-submit" @click="charge">确认充值
          </button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonPage} from '@ionic/vue';
import {charge, getBalance} from "@/api/home";

export default {
  name: 'Tab2',
  components: {
    IonContent, IonPage
  },
  data() {
    return {
      isLogin: true,
      startCharge: true,
      balance: 0,
      price: 0,
      restAmount: 0,
      chargeAmount: 0
    }
  },
  ionViewDidEnter() {
    this.onShow();
  },
  methods: {
    onShow: function () {
      this.page = 1;   //第一次加载，设置1
      this.activityList = [];  //放置返回数据的数组,设为空
      this.isFromSearch = true;  //第一次加载，设置true
      this.searchLoading = false;  //把"上拉加载"的变量设为false，不显示
      this.searchLoadingComplete = false //把“没有数据”设为false，隐藏
      this.getBalance();
    },
    getBalance: function () {
      getBalance().then(res => {
        this.isLogin = true;
        this.balance = res.data.balance; //获取数据数组
        this.price = res.data.price; //获取数据数组
        this.restAmount = res.data.restAmount; //获取数据数组
        this.startCharge = res.data.startCharge; //获取数据数组
      }).catch(res => {
        console.log(res.data);
        if (res.data.code === 401) {
          this.isLogin = false;
          this.$router.push({
            path: '/login'
          });
        } else if (res.data.code === 200) {
          this.isLogin = true;
        }
      });
    },
    charge: function () {
      charge({chargeAmount: this.chargeAmount}).then(res => {
        const div = document.createElement('div')
        /* 此处form就是后台返回接收到的数据 */
        console.log(res.data.body)
        div.innerHTML = res.data.body
        document.body.appendChild(div)
        document.forms[0].submit()
      }).catch(res => {
        console.log(res);
        console.log(res.data);
        if (res.data.code === 401) {
          this.isLogin = false;
        } else if (res.data.code === 200) {
          this.isLogin = true;
        }
      });
    }
  }
}
</script>
<style lang="css">
/* pages/order/list.wxss */
.page {
  --ion-background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

ion-item {
  --ion-background-color: white;
  --border-style: none;
  --background-activated: none;
}

.login-title {
  font-size: 20px;
  display: block;
  width: 300px;
  height: 50px;
  margin: 50px auto;
}

.login-button {
  display: block;
  width: 300px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0 auto;
  background-color: #d81e06;
  color: white;
  font-weight: bold;
  text-decoration-line: none;
}

.form-row {
  display: flex;
  align-items: center;
  width: 350px;
  margin: 20px auto;
  justify-content: flex-start;
}

.form-row > label {
  width: 120px;
}

.form-value {
  /*margin-left: 75px;*/
}

.balance {
  font-size: 14px;
  color: #F38900;
}

.cash-balance {
  font-size: 14px;
  line-height: 28px;
}

.pay-textbox {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 1px solid #F2F2F2;
  text-align: center;
  font-size: 12px;
  background: #FFF;
  vertical-align: middle;
  color: #333;
  padding: 0 10px;
  line-height: 28px;
}

.ui-ctrl {
  margin: 0;
  padding: 0;
  text-align: left;
}

.amount-tip {
  margin-left: 6px;
}

.form-submit-row {
  margin-top: 90px;
}

.confirm-button {
  margin: 0 auto;
  width: 95px;
  height: 36px;
  line-height: 36px;
  background-color: #ea2e2e;
  font-size: 12px;
}

.confirm-button {
  width: 112px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #FFF;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 14px;
  background: #F46767;
  border: none;
  padding: 0;
}
</style>
